

import React from 'react'
import {useSelector} from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateAuthorDasboard = () => {
    const authorToken = useSelector(state=>state.authorToken)

    // console.log(userToken)
  return (
    <div>
      {authorToken?<Outlet/>:<Navigate to="/authorlogin"/>}
    </div>
  )
}

export default PrivateAuthorDasboard

