// import React, { useState, useEffect, useContext } from 'react';
// import axios from 'axios';
// import '../CSS/AllAffiliates.css'; // Keep the existing CSS file
// import { Context } from './Context';

// const PendingOrders = () => {
//   const { getPendingOrdersUrl } = useContext(Context); // Assume the URL is provided via context
//   const [orders, setOrders] = useState([]);
//   const [search, setSearch] = useState('');

//   useEffect(() => {
//     const fetchOrders = async () => {
//       try {
//         const response = await axios.get("https://elexdondigitalacademy.com/api/fetch_pending_orders.php");

//         // Check if the response status is 'success'
//         if (response.data.status === 'success') {
//           setOrders(response.data.data); // Access the 'data' field for pending orders
//           console.log(response.data.data)
//         } else {
//           console.error('Failed to fetch orders:', response.data.message);
//         }
//       } catch (error) {
//         console.error('Error fetching orders:', error);
//       }
//     };

//     fetchOrders();
//   }, [getPendingOrdersUrl]); // Update the dependency to get orders URL

//   // Filter pending orders based on transaction_reference
//   const filteredOrders = orders.filter(order =>
//     order.transaction_reference.toLowerCase().includes(search.toLowerCase())
//   );

//   return (
//     <div className="affiliates-wrapper" style={{ width: "100%" }}>
//       <h1 className="affiliates-heading">Pending Orders</h1> {/* Update heading */}
//       <input
//         type="text"
//         placeholder="Search by Transaction Reference"
//         value={search}
//         onChange={(e) => setSearch(e.target.value)}
//         className="affiliates-search-input" // Keep the same CSS class
//       />
//       <div className="affiliates-list">
//         {filteredOrders.length > 0 ? (
//           filteredOrders.map((order) => (
//             <div key={order.id} className="affiliate-card"> {/* Keep CSS classes */}
//               {/* <div className="affiliate-detail">
//                 <span className="affiliate-label">Order ID:</span>
//                 <span className="affiliate-value">{order.id}</span>
//               </div> */}
//               <div className="affiliate-detail">
//                 <span className="affiliate-label">Customer Name:</span>
//                 <span className="affiliate-value">{order.name}</span>
//               </div>
//               <div className="affiliate-detail">
//                 <span className="affiliate-label">Email:</span>
//                 <span className="affiliate-value">{order.email}</span>
//               </div>
//               <div className="affiliate-detail">
//                 <span className="affiliate-label">Phone:</span>
//                 <span className="affiliate-value">{order.phone}</span>
//               </div>
//               <div className="affiliate-detail">
//                 <span className="affiliate-label">Transaction Reference:</span>
//                 <span className="affiliate-value">{order.transaction_reference}</span>
//               </div>
//               <div className="affiliate-detail">
//                 <span className="affiliate-label">Total Amount:</span>
//                 <span className="affiliate-value">NGN {order.total_amount}</span>
//               </div>
//               <div className="affiliate-detail">
//                 <span className="affiliate-label">Order Date:</span>
//                 <span className="affiliate-value">{new Date(order.order_date).toLocaleDateString()}</span>
//               </div>
//               <div className="affiliate-detail">
//                 <span className="affiliate-label">Cart items:</span>
//                 {order.cart_items.map((cartItem)=>(
//                     <div className="affiliate-value" style={{display:"flex",gap:"5px"}}>
//                         <p>Course ID:{cartItem.id},</p>
//                         <p>{cartItem.title}</p>
//                         <p>NGN {cartItem.price}</p>
                        
                    
//                     </div>
//                 ))}
//               </div>
//               <div >
//                 <button style={{
//                     padding:"5px",border:"none",borderRadius:"5px",marginRight:"10px",cursor:"pointer",
//                     backgroundColor:"rgba(0,0,255,0.5)",color:"white"
//                 }}>Approve</button>
//                 <button style={{
//                     padding:"5px",border:"1px solid blue",borderRadius:"5px",marginRight:"10px",cursor:"pointer",
//                     backgroundColor:"white",color:"blue"
//                 }}>Decline</button>
//               </div>
//             </div>
//           ))
//         ) : (
//           <div>No pending orders found.</div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default PendingOrders;


import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import '../CSS/AllAffiliates.css'; // Keep the existing CSS file
import { Context } from './Context';
import Swal from 'sweetalert2';

const PendingOrders = () => {
  const { getPendingOrdersUrl, approveOrderUrl, declineOrderUrl } = useContext(Context); // Assume the URLs are provided via context
  const [orders, setOrders] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get("https://elexdondigitalacademy.com/api/fetch_pending_orders.php");

        if (response.data.status === 'success') {
          setOrders(response.data.data);
          console.log(response.data.data)
        } else {
          console.error('Failed to fetch orders:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, [getPendingOrdersUrl]);



  const sendOrderEmailFunction = async (orderId,orderuser_id,
    ordertransaction_reference,
ordercurrency,
orderemail,
ordercart_items,
ordername,orderorder_date,orderphone,orderreferrer_code,
ordertotal_amount) => {
    const loadingAlert = Swal.fire({
      title: "Loading",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false
    });

    Swal.showLoading();

    const getCurrentDateTime = () => {
      const now = new Date();
      const offset = now.getTimezoneOffset() * 60000;
      const localTime = new Date(now.getTime() - offset);
      return localTime.toISOString().slice(0, 19).replace("T", " ");
    };

    const orderSummary = {
      userId: orderuser_id,
      date: orderorder_date,
      transactionRef: ordertransaction_reference,
      name: ordername,
      phone: orderphone,
      email: orderemail,
      referrerCode: orderreferrer_code,
      cartItems: ordercart_items.map(item => `${item.title} - ₦${new Intl.NumberFormat().format(item.price)}`),
      total: `₦${ordertotal_amount}`
    };

    try {
      const response = await axios.post("https://elexdondigitalacademy.com/api/send_order_email.php", {
        buyerEmail: orderemail,
        sellerEmail: 'info@elexdontech.com',
        orderSummary: JSON.stringify(orderSummary, null, 2)
      });

      if (response.status === 200) {
        Swal.fire({ icon: "success", text: "Order Approved" });
      } else {
        Swal.fire({ icon: "error", text: "Failed to send order summary." });
      }
    } catch (error) {
      Swal.fire({ icon: "error", text: "An error occurred while sending the order summary." });
    } finally {
      loadingAlert.close();
    }
  };


  // Handle approving an order
  const handleApprove = async (orderId,orderuser_id,
    ordertransaction_reference,
ordercurrency,
orderemail,
ordercart_items,
ordername,orderorder_date,orderphone,orderreferrer_code,
ordertotal_amount) => {
    // Confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to approve this order?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, approve it!'
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: 'Processing...',
        text: 'Your request is being processed!',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      try {
        const response = await axios.post("https://elexdondigitalacademy.com/api/approve_order.php", { order_id: orderId });

        if (response.data.status === 'success') {
          Swal.fire('Approved!', 'The order has been approved.', 'success');
          setOrders((prevOrders) => prevOrders.filter((order) => order.id !== orderId));
          sendOrderEmailFunction(orderId,orderuser_id,
            ordertransaction_reference,
        ordercurrency,
        orderemail,
        ordercart_items,
        ordername,orderorder_date,orderphone,orderreferrer_code,
        ordertotal_amount)
        } else {
          Swal.fire('Error!', response.data.message, 'error');
          console.error('Failed to approve order:', response.data.message);
        }
      } catch (error) {
        Swal.fire('Error!', 'An error occurred while approving the order.', 'error');
        console.error('Error approving order:', error);
      }
    }
  };





  // Handle declining an order
  const handleDecline = async (orderId) => {
    // Confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to decline this order?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, decline it!'
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: 'Processing...',
        text: 'Your request is being processed!',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      try {
        const response = await axios.post("https://elexdondigitalacademy.com/api/decline_order.php", { order_id: orderId });

        if (response.data.status === 'success') {
          Swal.fire('Declined!', 'The order has been declined.', 'success');
          setOrders((prevOrders) => prevOrders.filter((order) => order.id !== orderId));
        } else {
          Swal.fire('Error!', response.data.message, 'error');
          console.error('Failed to decline order:', response.data.message);
        }
      } catch (error) {
        Swal.fire('Error!', 'An error occurred while declining the order.', 'error');
        console.error('Error declining order:', error);
      }
    }
  };


  const filteredOrders = orders.filter(order =>
    order.transaction_reference.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="affiliates-wrapper" style={{ width: "100%" }}>
      <h1 className="affiliates-heading">Pending Orders</h1>
      <input
        type="text"
        placeholder="Search by Transaction Reference"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="affiliates-search-input"
      />
      <div className="affiliates-list">
        {filteredOrders.length > 0 ? (
          filteredOrders.map((order) => (
            <div key={order.id} className="affiliate-card">
              <div className="affiliate-detail">
                <span className="affiliate-label">Customer Name:</span>
                <span className="affiliate-value">{order.name}</span>
              </div>
              <div className="affiliate-detail">
                <span className="affiliate-label">Email:</span>
                <span className="affiliate-value">{order.email}</span>
              </div>
              <div className="affiliate-detail">
                <span className="affiliate-label">Phone:</span>
                <span className="affiliate-value">{order.phone}</span>
              </div>
              <div className="affiliate-detail">
                <span className="affiliate-label">Transaction Reference:</span>
                <span className="affiliate-value">{order.transaction_reference}</span>
              </div>
              <div className="affiliate-detail">
                <span className="affiliate-label">Total Amount:</span>
                <span className="affiliate-value">NGN {order.total_amount}</span>
              </div>
              <div className="affiliate-detail">
                <span className="affiliate-label">Order Date:</span>
                <span className="affiliate-value">{new Date(order.order_date).toLocaleDateString()}</span>
              </div>
              <div className="affiliate-detail">
                <span className="affiliate-label">Cart items:</span>
                {order.cart_items.map((cartItem) => (
                  <div className="affiliate-value" style={{ display: "flex", gap: "5px" }} key={cartItem.id}>
                    <p>Course ID: {cartItem.id},</p>
                    <p>{cartItem.title}</p>
                    <p>NGN {cartItem.price}</p>
                  </div>
                ))}
              </div>
              <div>
                <button
                  onClick={() => handleApprove(order.id,order.user_id,
                    order.transaction_reference,
                order.currency,
            order.email,
        order.cart_items,
    order.name,order.order_date,order.phone,order.referrer_code,
order.total_amount)}
                  style={{
                    padding: "5px",
                    border: "none",
                    borderRadius: "5px",
                    marginRight: "10px",
                    cursor: "pointer",
                    backgroundColor: "rgba(0,0,255,0.5)",
                    color: "white"
                  }}
                >
                  Approve
                </button>
                <button
                  onClick={() => handleDecline(order.id)}
                  style={{
                    padding: "5px",
                    border: "1px solid blue",
                    borderRadius: "5px",
                    marginRight: "10px",
                    cursor: "pointer",
                    backgroundColor: "white",
                    color: "blue"
                  }}
                >
                  Decline
                </button>
              </div>
            </div>
          ))
        ) : (
          <div>No pending orders found.</div>
        )}
      </div>
    </div>
  );
};

export default PendingOrders;

