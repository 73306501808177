


import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import axios from 'axios'
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import ManageLessons from './ManageLessons';

const Container = styled.div`
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
`;

const CourseCard = styled.div`
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    margin: 10px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const CourseTitle = styled.h4`
    font-size: 24px;
`;

const LessonList = styled.ul`
    list-style: none;
    padding: 0;
`;

const LessonItem = styled.li`
    margin: 10px 0;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:10px;
`;

const ErrorMessage = styled.p`
    color: red;
`;

const H4 = styled.h4`
    color: rgba(0,0,255,0.5);
`;

const P = styled.p`
    font-size: 0.8rem;
`;

const Input = styled.input`
    padding: 5px;
    font-size: 0.7rem;
`;

const Label = styled.label`
    margin-top: 5px;
    font-size: 0.7rem;
`;

const Button = styled.button`
    padding: 5px;
    margin-top: 5px;
    cursor: pointer;
    margin-right: 10px;
    background-color:    rgba(0,0,255,0.5);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 0.7rem;
`;

const Button2 = styled.button`
    padding: 5px;
    margin-top: 5px;
    cursor: pointer;
    margin-right: 10px;
    background-color: white;
    color: blue;
    border: 1px solid blue;
    border-radius: 5px;
    font-size: 0.7rem;
`;

const EditButtonWrap = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
`;

const CheckBox = styled.input`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const LessonsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: rgba(0,0,0,0.4);
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
`;

const LessonsWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 5px;
    overflow-y: scroll;
    padding: 20px;
`;

const VideoModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;

const VideoPlayer = styled.video`
    width: 80%;
    height: auto;
    border-radius: 10px;
`;

const Img = styled.img`
    width:50px;
    height:50px;
    border-radius:50%;
    margin-top:5px;
`


const CoursesAndLessonsByAuthor = () => {
    const [courses, setCourses] = useState([]);
    const [error, setError] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null); // For video modal
    const [editingCourse, setEditingCourse] = useState(null); // Store editing state
    const authorInfo = useSelector(state => state.authorInfo);
    const navigate = useNavigate();

    const fetchCourses = async () => {
        const loadingAlert = Swal.fire({ text: "Fetching Courses..." });
        Swal.showLoading();
        try {
            const response = await fetch(`https://elexdondigitalacademy.com/api/get_courses_with_lessons_by_author.php?authorId=${authorInfo.id}`);
            const data = await response.json();
            if (data.success) {
                setCourses(data.courses);
            } else {
                setError(data.error);
            }
        } catch (err) {
            setError('An error occurred while fetching courses.');
            console.error(err);
        } finally {
            loadingAlert.close();
        }
    };

    useEffect(() => {
        fetchCourses();
    }, [authorInfo.id]);

    const handleEditToggle = (courseId) => {
        setEditingCourse(editingCourse === courseId ? null : courseId);
    };

    const handleInputChange = (courseId, field, value) => {
        setCourses(courses.map(course =>
            course.id === courseId ? { ...course, [field]: value } : course
        ));
    };

    const handleImageChange = (courseId, event) => {
        const file = event.target.files[0];
        setCourses(courses.map(course =>
            course.id === courseId ? { ...course, newImage: file } : course
        ));
    };

    const handleUpdateCourse = async (course) => {
        const formData = new FormData();
        formData.append('courseId', course.id);
        formData.append('title', course.title);
        formData.append('price', course.price);
        formData.append('description', course.description); // Ensure this has the correct value
    
        // Only append the old image name if it exists and a new image is being uploaded
        if (course.newImage) {
            formData.append('image', course.newImage);
            formData.append('oldImage', course.image); // Pass old image name for deletion
        }
    
        const loadingAlert = Swal.fire({ text: "Updating Course..." });
        Swal.showLoading();
    
        try {
            const response = await axios.post('https://elexdondigitalacademy.com/api/update_course.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
    
            if (response.data.success) {
                Swal.fire({ title: 'Updated!', text: response.data.message, icon: 'success' });
                fetchCourses(); // Refresh the course list
                setEditingCourse(null); // Reset editing state
            } else {
                Swal.fire({ title: 'Error', text: response.data.error, icon: 'error' });
            }
        } catch (error) {
            Swal.fire({ title: 'Error', text: 'There was an error updating the course. Please try again later.', icon: 'error' });
            console.error('Error updating course:', error);
        } finally {
            loadingAlert.close();
        }
    };
    

    const handleManageLessons = (courseId)=>{
        // alert('yes')
        const updatedArray = courses.map((course)=>{
            if(course.id===courseId){
                return {...course,openModal:!course.openModal}
            }
            return course
        })
        setCourses(updatedArray)
    }


const [searchInput,setSearchInput]= useState("")

useEffect(() => {
    if (searchInput) {
        // Filter courses based on the search input
        const updatedArray = courses.filter((course) =>
            course.title.toLowerCase().includes(searchInput.toLowerCase())
        );
        setCourses(updatedArray); // Update courses to the filtered array
    } else {
        fetchCourses() // Reset to the initial courses if search input is empty
    }
}, [searchInput]); // Add i



const handleDeleteCourse = async (courseId) => {
  const confirmResult = await Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  });

  if (confirmResult.isConfirmed) {
    Swal.fire({
      title: 'Deleting...',
      text: 'Please wait while the course is being deleted.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      const response = await axios.post(
        'https://elexdondigitalacademy.com/api/delete_course.php', 
        qs.stringify({ courseId }), // Use qs.stringify to handle form-encoded format
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // Set the appropriate content type
          },
        }
      );

      if (response.data.success) {
        Swal.fire({
          title: 'Deleted!',
          text: response.data.message,
          icon: 'success'
        });
        fetchCourses()
        // Update your UI to remove the course
      } else {
        Swal.fire({
          title: 'Error',
          text: response.data.error,
          icon: 'error'
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'There was an error deleting the course. Please try again later.',
        icon: 'error'
      });
      console.error('Error deleting course:', error);
    }
  }
};


    return (
        <Container>
            <Input placeholder='Search Course by Name' onChange={(e)=>setSearchInput(e.target.value)}/>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {courses.length > 0 ? (
                courses.map(course => (
                    <CourseCard key={course.id}>
                        <Label>Title:</Label>
                        <Input 
                            value={course.title} 
                            readOnly={editingCourse !== course.id} 
                            onChange={(e) => handleInputChange(course.id, 'title', e.target.value)} 
                        />
                        <Label>Price (NGN):</Label>
                        <Input 
                            value={course.price} 
                            readOnly={editingCourse !== course.id} 
                            onChange={(e) => handleInputChange(course.id, 'price', e.target.value)} 
                        />
                        <Label>Description:</Label>
                        <Input 
                            value={course.description} 
                            readOnly={editingCourse !== course.id} 
                            onChange={(e) => handleInputChange(course.id, 'description', e.target.value)} 
                        />
                        <Img src={`https://elexdondigitalacademy.com/api/uploads/${course.image}`} alt="courseImage"/>
                        {editingCourse === course.id && (
                            <>
                                <Label>Change Image:</Label>
                                <Input type="file" onChange={(e) => handleImageChange(course.id, e)} />
                            </>
                        )}
                        <Button onClick={() => navigate(`/course/${course.id}`)}>View Course</Button>
                        <Button2 onClick={() => handleDeleteCourse(course.id)}>Delete Course</Button2>
                        <EditButtonWrap>
                            <CheckBox type="checkbox" checked={editingCourse === course.id} onChange={() => handleEditToggle(course.id)} /> 
                            <P>Enable Editing</P>
                            {editingCourse === course.id && (
                                <Button onClick={() => handleUpdateCourse(course)}>Save Changes</Button>
                            )}
                            <Button2 onClick={()=>handleManageLessons(course.id)}>Manage Lessons</Button2>
                        </EditButtonWrap>
                        {course.openModal&&<ManageLessons courseId={course.id} course={course} handleManageLessons={handleManageLessons}/>}
                    </CourseCard>
                ))
            ) : (
                <p>No courses available.</p>
            )}
        </Container>
    );
};

export default CoursesAndLessonsByAuthor;

