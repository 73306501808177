import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FaCartShopping, FaClosedCaptioning } from 'react-icons/fa6';
import { FaShareAlt } from 'react-icons/fa';
import "../CSS/LatestCourses.css";
import cp1 from "../Images/cp1.jpeg";
import cp2 from "../Images/cp2.jpeg";
import cp3 from "../Images/cp3.jpeg";
import cp4 from "../Images/cp4.jpeg";
import cp5 from "../Images/cp5.jpeg";
import cp6 from "../Images/cp6.jpeg";
import cp7 from "../Images/cp7.jpeg";
import cp8 from "../Images/cp8.jpeg";
import "../CSS/AllCourses.css";
import ManualScrollToTop from './ManualScrollToTop';
import { addToCart } from '../Features/Slice'; // Adjust the path as per your project structure
import Swal from 'sweetalert2'
import Features from "./Featrures"
import { Context } from './Context';
import "../CSS/SearchPage.css"
import { useNavigate } from 'react-router-dom';


const SearchPage = () => {
  const navigate = useNavigate()
    const {searchResult,navigateState,setNavigateState}=useContext(Context)
  const dispatch = useDispatch();

  

  const handleAddToCart = (course) => {
    dispatch(addToCart(course));
    Swal.fire({icon:"success",text:"item added to cart",showConfirmButton:false,timer:2000})
  };

  useEffect(()=>{
        setNavigateState(false)
  },[])


  const handleShare = (course) => {
    if (navigator.share) {
      navigator.share({
        title: course.title,
        text: course.description,
        url: window.location.origin + '/course/' + course.id,
      }).catch(error => console.error('Error sharing', error));
    } else {
      navigator.clipboard.writeText(window.location.origin + '/course/' + course.id);
      Swal.fire({ icon: "info", text: "URL copied to clipboard", showConfirmButton: false, timer: 2000 });
    }
  };

  return (
    <div className='LatestCourses' style={{ paddingTop: "0px", padding: "0px 0px" }}>
      
      <div className='AllCoursesHeader' style={{justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column",gap:"10px"}}>
        <h1 style={{ color: "rgba(255,255,255,0.9)", textAlign: "center", position: 'relative' }}>SEARCH RESULT : FOUND ({searchResult.length}) ITEMS</h1>
        <button style={{ color: "blue", textAlign: "center", position: 'relative',cursor:"pointer",fontWeight:"bold",padding:"5px 10px" }} onClick={()=>navigate("/allcourses")}>X - Close Search</button>
        {/* <h2 style={{ color: "rgba(255,255,255,0.9)", textAlign: "center", position: 'relative' }}>Category name</h2> */}
        {/* <button>View All Courses</button> */}
      </div>

      <Features/>
      {searchResult.length===0?<div className='NoResultWrap'><h3>No result found</h3><button onClick={()=>window.history.back()}>Back</button></div>:
      <div className='LatestCoursesBody' style={{ padding: "100px 0px" }}>
        {searchResult.map(course => (
          <div className='Card' key={course.id}>  
            <div className='CardUp'>
            <img src={`https://elexdondigitalacademy.com/api/uploads/${course.image}`} 
              alt={course.title} 
              onClick={() => navigate(`/course/${course.id}`)}
              style={{cursor:"pointer"}}
              />
            </div>
            <div className='CardDown'>
              <div className='CardDownAuthor'><span className='CardDownAuthorLeft'>{course.author.slice(0,1)}</span>
              <span className='CardDownAuthorRight' style={{fontSize:"small"}}>{course.author}</span></div>
              <h4>{course.title.slice(0,30)}{course.title.length>30?'...':""}</h4>
              <p>₦ {course.price}</p>
              <p onClick={()=>navigate(`/course/${course.id}`)} style={{marginBottom:"5px",fontWeight:"400",fontSize:"0.8rem",cursor:"pointer"}}>View More</p>
              <div className='CardButtonAndShareWrap'>
                <button onClick={() => handleAddToCart(course)}><FaCartShopping /> Buy Now</button>
                <FaShareAlt className='CardShare' onClick={() => handleShare(course)}/>
              </div>
            </div>
          </div>
        ))}
      </div>}
      <ManualScrollToTop />
    </div>
  )
}

export default SearchPage;
