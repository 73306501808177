import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Context } from './Context';
import '../CSS/MyCoursesPage.css';
import { Link } from 'react-router-dom';

const MyCoursesPage = () => {
  const [myCourses, setMyCourses] = useState([]);
  const { orderListGetUrl, courses } = useContext(Context);
  const userInfo = useSelector(state => state.userInfo);

  const fetchOrders = async () => {
    const loadingAlert = Swal.fire({
      text: "Fetching your courses",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false
    });

    Swal.showLoading();

    try {
      const userId = userInfo.id; // Ensure this is the correct value
      if (!userId) {
        throw new Error('User ID is missing');
      }

      const response = await axios.post(orderListGetUrl, { user_id: userId }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log('Orders fetched:', response.data);
      console.log(userInfo.id)
      setMyCourses(response.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
      Swal.fire({
        icon: "error",
        title: "Fetch Orders Failed",
        text: error.response?.data?.error || 'An error occurred.',
        showConfirmButton: false,
        timer: 3000
      });
    } finally {
      loadingAlert.close();
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []); // Fetch orders on component mount



  return (
    <div className="my-courses-page">
      <h2>MY COURSES</h2>
      <ul>
        {myCourses.map((order) => (
          <div className='my-course' key={order.id}>
            <p>Transaction: {order.transaction_reference}</p>
            <ul>
              {order.cart_items.map((cartItem) => {
                const course = courses.find(c => c.id === cartItem.id);
                return (
                  <li key={cartItem.id} className="OneCourse">
                    {/* {course && <img src={`https://elexdondigitalacademy.com/api/uploads/${cartItem.image}`} alt="courseImg" />} */}
                    <p>{cartItem.title}</p>
                    <Link to={`/coursedetail/${cartItem.id}`}>
                      <button>Take Course</button>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default MyCoursesPage;
