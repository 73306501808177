import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import '../CSS/AllAffiliates.css'; // Keep the existing CSS file
import { Context } from './Context';

const AllAuthors = () => {
  const { getAllAuthorsUrl } = useContext(Context); // Use a context or a constant for the authors URL
  const [authors, setAuthors] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchAuthors = async () => {
      try {
        const response = await axios.get("https://elexdondigitalacademy.com/api/getAuthors.php");

        // Check if the response status is 'success'
        if (response.data.status === 'success') {
          setAuthors(response.data.data); // Update to handle author data
        } else {
          console.error('Failed to fetch authors:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching authors:', error);
      }
    };

    fetchAuthors();
  }, [getAllAuthorsUrl]); // Update to fetch from the authors URL

  const filteredAuthors = authors.filter(author =>
    author.email.toLowerCase().includes(search.toLowerCase()) // Filter based on author name
  );

  return (
    <div className="affiliates-wrapper" style={{ width: "100%" }}>
      <h1 className="affiliates-heading">Authors</h1> {/* Update the heading */}
      <input
        type="text"
        placeholder="Search by Author Email"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="affiliates-search-input" // Keep the same class for styling
      />
      <div className="affiliates-list">
        {filteredAuthors.length > 0 ? (
          filteredAuthors.map((author) => (
            <div key={author.id} className="affiliate-card">
              <div className="affiliate-detail">
                <span className="affiliate-label">Name:</span>
                <span className="affiliate-value">{author.full_name}</span>
              </div>
              <div className="affiliate-detail">
                <span className="affiliate-label">Email:</span>
                <span className="affiliate-value">{author.email}</span>
              </div>
              <div className="affiliate-detail">
                <span className="affiliate-label">Phone:</span>
                <span className="affiliate-value">{author.phone_number}</span> {/* Example to display author's books */}
              </div>
          
            </div>
          ))
        ) : (
          <div>No authors found.</div>
        )}
      </div>
    </div>
  );
};

export default AllAuthors;
