import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FaBackward } from 'react-icons/fa';
import Swal from 'sweetalert2';
import axios from 'axios';
import "../CSS/AllCoursesDetailPage.css";
import "../CSS/CourseDetailPage.css";

const CourseDetailPage = () => {  
  const location = useLocation()
  const { id } = useParams();
  const dispatch = useDispatch();
  const [course, setCourse] = useState({});
  const [currentVideoUrl, setCurrentVideoUrl] = useState("");  // State to hold the current video URL

  // Get one course by ID
  const getCourseById = async (id) => {
    const loadingAlert = Swal.fire({text: "Fetching Course Details..."});
    Swal.showLoading();
    try {
      // const response = await axios.get(`https://elexdondigitalacademy.com/api/get_course.php?id=${id}`);
      const response = await axios.get(`https://elexdondigitalacademy.com/api/get_course_by_id.php?id=${id}`);
    
      console.log(response.data);
      setCourse(response.data.course);
    } catch (error) {
      console.error(error);
    } finally {
      loadingAlert.close();
    }
  };

  useEffect(() => {
    getCourseById(id);
  }, [id]);

  useEffect(() => {
    Swal.fire({title: "Happy Learning", text: "Click on the lesson titles to start"});
  }, []);

  // Function to handle lesson title click and play the video
  const handleLessonClick = (videoUrl) => {
    setCurrentVideoUrl(videoUrl);
    window.scroll(0, 0);  // Scroll to the top of the page
  };

  return (
    course === null ? (
      <div className='ECourseDetail' key={location.pathname}>Fetching Course Details... </div>
    ) : (
      <div className='ECourseDetail' key={location.pathname}>
        <img 
          className='ECourseImage' 
          src={`https://elexdondigitalacademy.com/api/uploads/${course.image}`} 
          alt={course?.title} 
          style={{width: "100px", height: "100px", borderRadius: "50%", marginBottom: "20px"}}
        />
        <video 
          key={currentVideoUrl}  // Force re-render of the video element when URL changes
          controls 
          autoPlay 
          className='CourseVideoPlayer'
        >
          <source src={currentVideoUrl} type="video/mp4" />  {/* Set the current video URL */}
        </video>
        <div className='ECourseInfo'>
          <h1 className='ECourseTitle'>{course?.title}</h1>
          <p>Author: {course?.author}</p>
          <p className='ECourseType'>{course?.type}</p>
          <p className='ECourseDescription'>{course?.description}</p>
          <h3 className='ECourseOutlineHeader'>Course Lessons</h3>
         
          <div className='ECourseOutline'>
            {course?.lessons?.map((lesson, index) => (
              <div className='CourseChapter' key={lesson.id}>
                <p>Lesson {index + 1}</p>
                <p
                  style={{ textDecoration: "underline", color: "blue", cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => handleLessonClick(`https://elexdondigitalacademy.com/api/uploads/videos/${lesson.video_file}`)}  // Set the video URL on click
                >
                  {lesson.title}
                </p>
              </div>
            ))}
          </div>
  
        </div>
        <button
          style={{
            cursor: "pointer",
            padding: "5px 10px",
            backgroundColor: "rgba(0,0,255,0.7)",
            color: "white",
            border: "none",
            borderRadius: "5px"
          }}
          onClick={() => window.history.back()}
        >
          <FaBackward /> Back
        </button>
      </div>
    )
  );
};

export default CourseDetailPage;
