// import React, { useState, useEffect } from 'react';
// import { useLocation, useParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { FaCartShopping } from 'react-icons/fa6';
// import { FaBackward, FaShareAlt } from 'react-icons/fa';
// import Swal from 'sweetalert2';
// import { addToCart } from '../Features/Slice';
// import "../CSS/AllCoursesDetailPage.css";
// import axios from 'axios';

// const AllCoursesDetailPage = () => {
//   const location = useLocation();
//   const { id } = useParams();
//   const dispatch = useDispatch();
//   const [course, setCourse] = useState({});

//   // Get one course by ID
//   const getCourseById = async (id) => {
//     const loadingAlert = Swal.fire({text:"Fetching Course Details..."})
//     Swal.showLoading();
//     try {
//       const response = await axios.get(`https://elexdondigitalacademy.com/api/get_course.php?id=${id}`);
//       console.log(response.data);
//       setCourse(response.data.course);
//     } catch (error) {
//       console.error(error);
//     }finally{
//       loadingAlert.close();
//     }
//   };

//   useEffect(() => {
//     getCourseById(id);
//   }, [id]);

//   const handleAddToCart = () => {
//     dispatch(addToCart(course));
//     Swal.fire({ icon: "success", text: "This course is added to your cart", showConfirmButton: false, timer: 2000 });
//   };

//   const handleShare = () => {
//     if (navigator.share) {
//       navigator.share({
//         title: course.title,
//         text: course.description,
//         url: window.location.href,
//       }).catch(error => console.error('Error sharing', error));
//     } else {
//       navigator.clipboard.writeText(window.location.href);
//       Swal.fire({ icon: "info", text: "URL copied to clipboard", showConfirmButton: false, timer: 2000 });
//     }
//   };


  
//     return (
//       course===null?<div className='ECourseDetail' key={location.pathname}>Fetching Course Details... </div>:
//       <div className='ECourseDetail' key={location.pathname}>
//         <img className='ECourseImage' src={course?.imgSrc} alt={course?.title} />
//         <div className='ECourseInfo'>
//           <h1 className='ECourseTitle'>{course?.title}</h1>
//           <p>Author: {course?.author}</p>
//           <p className='ECoursePrice'>₦ {Number(course?.price).toLocaleString()}</p>
//           <p className='ECourseType'>{course?.type}</p>
//           <p className='ECourseDescription'>{course?.description}</p>
//           <h3 className='ECourseOutlineHeader'>Course Lessons</h3>
         
//           <div className='ECourseOutline'>
            
//             {course?.lessons?.map((lesson,index) => (

//               <div className='CourseChapter' key={lesson.id}>
//                 <p>Lesson {index+1}</p>
//                  <p>{lesson.title}</p>
//                  {/* <p>{lesson.videoUrl}</p> */}
//               </div>
//             ))}
//           </div>
//           <button className='EAddToCartButton' onClick={handleAddToCart}><FaCartShopping /> Add to Cart</button>
//           <FaShareAlt className='EShareIcon' onClick={handleShare} />
//         </div>
//         <button style={{
//           cursor: "pointer",
//           padding: "5px 10px",
//           backgroundColor: "rgba(0,0,255,0.7)",
//           color: "white",
//           border: "none",
//           borderRadius: "5px"
//         }} onClick={() => window.history.back()}><FaBackward /> Back</button>
//       </div>
//     );
//   }

  


// export default AllCoursesDetailPage;



import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FaCartShopping } from 'react-icons/fa6';
import { FaBackward, FaShareAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { addToCart } from '../Features/Slice';
import "../CSS/AllCoursesDetailPage.css";
import axios from 'axios';

const AllCoursesDetailPage = () => {
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [course, setCourse] = useState(null); // Initialize course as null

  // Get one course by ID
  const getCourseById = async (id) => {
    const loadingAlert = Swal.fire({ text: "Fetching Course Details..." });
    Swal.showLoading();
    try {
      const response = await axios.get(`https://elexdondigitalacademy.com/api/get_course_by_id.php?id=${id}`);
      console.log(response.data);

      if (response.data.success) {
        setCourse(response.data.course);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.error,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while fetching course details.',
        showConfirmButton: false,
        timer: 2000,
      });
    } finally {
      loadingAlert.close();
    }
  };

  useEffect(() => {
    getCourseById(id);
  }, [id]);

  const handleAddToCart = () => {
    if (course) {
      dispatch(addToCart(course));
      Swal.fire({ icon: "success", text: "This course is added to your cart", showConfirmButton: false, timer: 2000 });
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: course.title,
        text: course.description,
        url: window.location.href,
      }).catch(error => console.error('Error sharing', error));
    } else {
      navigator.clipboard.writeText(window.location.href);
      Swal.fire({ icon: "info", text: "URL copied to clipboard", showConfirmButton: false, timer: 2000 });
    }
  };

  return (
    course === null ? (
      <div className='ECourseDetail' key={location.pathname}>Fetching Course Details...</div>
    ) : (
      <div className='ECourseDetail' key={location.pathname}>
        <img className='ECourseImage' src={`https://elexdondigitalacademy.com/api/uploads/${course?.image}`} alt={course?.title} />
        <div className='ECourseInfo'>
          <h1 className='ECourseTitle'>{course?.title}</h1>
          <p>Author: {course?.author}</p>
          <p className='ECoursePrice'>₦ {Number(course?.price).toLocaleString()}</p>
          <p className='ECourseType'>{course?.type}</p>
          <p className='ECourseDescription'>{course?.description}</p>
          <h3 className='ECourseOutlineHeader'>Course Lessons</h3>

          <div className='ECourseOutline'>
            {course?.lessons?.map((lesson, index) => (
              <div className='CourseChapter' key={lesson.id}>
                <p>Lesson {index + 1}</p>
                <p>{lesson.title}</p>
                {/* <p>{lesson.videoUrl}</p> */}
              </div>
            ))}
          </div>
          <button className='EAddToCartButton' onClick={handleAddToCart}><FaCartShopping /> Add to Cart</button>
          <FaShareAlt className='EShareIcon' onClick={handleShare} />
        </div>
        <button style={{
          cursor: "pointer",
          padding: "5px 10px",
          backgroundColor: "rgba(0,0,255,0.7)",
          color: "white",
          border: "none",
          borderRadius: "5px"
        }} onClick={() => window.history.back()}><FaBackward /> Back</button>
      </div>
    )
  );
};

export default AllCoursesDetailPage;
