import React, { useContext, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { Context } from './Context';

const Container = styled.div`
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: rgba(0,0,255,0.6);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin: 10px 0 5px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 15px;
`;

const TextArea = styled.textarea`
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 15px;
`;

const Button = styled.button`
  padding: 12px;
  font-size: 16px;
  background-color: rgba(0,0,255,0.5);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0,0,255,0.7);
  }
`;

const Select = styled.select`
    padding:10px;
    outline:none;
`

const CoursePostPage = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [price, setPrice] = useState('');
  
  const [type, setType] = useState('Video course');
  const authorInfo = useSelector((state) => state.authorInfo); // Get authorInfo from Redux
  const [author, setAuthor] = useState('');
  const {setAdminMenu}=useContext(Context)
//   console.log(authorInfo,"hey")

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // Set image file to state
  };

  const clearFormFields = () => {
    setTitle('');
    setDescription('');
    setPrice('');
    setAuthor('');
    setType('');
    setImage(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('author', author);
    formData.append('type', type);
    formData.append('image', image); // Append image file
    formData.append('authorInfoId', authorInfo.id); // Append authorInfoId

    // Show loading alert
    Swal.fire({
      title: 'Uploading Course...',
      text: 'Please wait while we upload your course.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      const response = await axios.post('https://elexdondigitalacademy.com/api/create_course.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data .success) {
        // Success message
        Swal.fire({
          icon: 'success',
          title: 'Course Created',
          text: 'Your course has been uploaded successfully!',
        });
        setAdminMenu(0)
        // Clear form fields
        clearFormFields();
      } else {
        // Error message from server
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.error || 'An error occurred while uploading the course.',
        });
      }
    } catch (error) {
      // Failure message for network or server errors
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to upload course. Please try again later.',
      });
      console.error(error);
    }
  };

  return (
    <Container>
      <Title>Create New Course</Title>
      <Form onSubmit={handleSubmit}>
        <Label>Course Title:</Label>
        <Input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value.toUpperCase())}
          required
        />

        <Label>Author:</Label>
        {authorInfo.email==="esomesther@gmail.com"||
        authorInfo.email==="support@elexdondigitalacademy.com"?
        <Select onChange={(e)=>setAuthor(e.target.value)} required>
            <option>--Select Author--</option>
            <option value={"ELEXDON DIGITAL ACADEMY"}>ELEXDON DIGITAL ACADEMY</option>
        </Select>:
        <Input
          type="text"
          value={author}
          onChange={(e) => setAuthor(e.target.value.toUpperCase())}
          required
        />
        }

        <Label>Price (NGN): </Label>
        <Input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          required
        />

        <Label>Type:</Label>
        <Input
          type="text"
          value={type}
        //   onChange={(e) => setType('')}
          required
          disabled
        />

        <Label>Course Description:</Label>
        <TextArea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <Label>Upload Course Cover Image (.jpg, .jpeg, .png) 10mb max:</Label>
        <Input
          type="file"
          onChange={handleImageChange}
          accept="image/png, image/jpeg"
          required
        />

        <Button type="submit">Create Course</Button>
      </Form>
    </Container>
  );
};

export default CoursePostPage;
