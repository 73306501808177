// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom'; // To get the course ID from the URL
// import axios from 'axios';
// import Swal from 'sweetalert2';
// import styled from 'styled-components'; // Import Styled Components

// // Styled Components



// const LessonsContainer = styled.div`
//     display: flex;
//     width: 100%;
//     height: 100%;
//     justify-content: center;
//     align-items: center;
//     padding: 20px;
//     background-color: rgba(0,0,0,0.4);
//     z-index: 99;
//     position: fixed;
//     top: 0;
//     left: 0;
// `;


// const Wrapper = styled.div`

//   display: flex;
//     justify-content: flex-start;
//     align-items: center;
//     flex-direction: column;
//     width: 100%;
//     height: 100%;
//     background-color: white;
//     border-radius: 5px;
//     overflow-y: scroll;
//     padding: 5px;   
//     padding-top:50px;
//     position:relative;

//   @media (max-width: 768px) {
//     padding: 10px;
//   }
// `;




// const CourseTitle = styled.h2`
//   text-align: center;
// //   margin-bottom: 20px;  
//   font-size: 1rem;
//   color: rgba(0,0,255,0.6);
  
//   @media (max-width: 768px) {
//     // font-size: 2em;
//     margin-bottom: 20px;
//   }

//   @media (max-width: 480px) {
//     // font-size: 1.8em;
//   }
// `;

// const CourseDescription = styled.p`
//   text-align: center;
//   color: #666;
//   margin-bottom: 40px;
// `;

// const LessonsGrid = styled.div`
//   display: flex;
//   flex-direction:column;
// //   grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
//   grid:10px;
//   width:100%;
//   padding:20px;
// `;

// const LessonCard = styled.div`
//     width:100%;
//   background-color: lightgray;
//   border-radius: 8px;
//   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
//   padding: 10px;
//   text-align: center;
//   transition: transform 0.3s ease, box-shadow 0.3s ease;
//   margin-bottom:10px;

//   &:hover {
//     // transform: translateY(-10px);
//     // box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
//     // border: 1px solid gray;
//   }

//   @media (max-width: 480px) {
//     padding: 10px;
//   }
// `;

// const LessonTitle = styled.h2`
//   font-size: 0.9rem;
//   margin-bottom: 5px;
//   color: #444;

//   @media (max-width: 768px) {
//     // font-size: 1.2em;
//   }
// `;

// const PlayButton = styled.button`
//   background-color: #28a745;
//   color: white;
//   padding: 5px;
//   border: none;
//   border-radius: 5px;
//   font-size: 0.7rem;
//   cursor: pointer;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: #218838;
//   }

//   @media (max-width: 768px) {
//     // font-size: 0.9em;
//     padding: 8px 16px;
//   }

//   @media (max-width: 480px) {
//     font-size: 0.8em;
//     padding: 6px 12px;
//   }
// `;

// const ErrorMessage = styled.p`
//   color: red;
//   font-size: 1.2em;
//   text-align: center;
//   margin-top: 20px;
// `;

// const LoadingMessage = styled.p`
//   text-align: center;
//   font-size: 1.2em;
// `;

// const ButtonsContainer = styled.div`
//     display:flex;
//     justify-content:center;
//     align-items:center;
//     gap:10px;
// `

// const Button = styled.button`
//     padding: 5px;
//     margin-top: 5px;
//     cursor: pointer;
    
//     background-color:    rgba(0,0,255,0.5);
//     color: white;
//     border: none;
//     border-radius: 5px;
//     font-size: 0.7rem;
// `;

// const Button2 = styled.button`
//     padding: 5px;
//     margin-top: 5px;
//     cursor: pointer;
//     margin-right: 10px;
//     background-color: lightgray;
//     color: blue;
//     border: 1px solid blue;
//     border-radius: 5px;
//     font-size: 0.7rem;
// `;

// const Back = styled.div`
//     cursor:pointer;
//     border:1px solid blue;
//     border-radius:5px;
//     padding:5px;
//     position:absolute;
//     top:10px;
//     right:10px;
//     color:blue;
//     z-index:99;
// `

// // Component
// const ManageLessons = ({courseId,course,handleManageLessons}) => {
//     // const { courseId } = useParams(); // Get the courseId from the URL
//     const [lessons, setLessons] = useState(null);
//     const [error, setError] = useState(null);

//     // Fetch course and lessons by course ID
//     useEffect(() => {
//         const fetchCourse = async () => {
//             const loadingAlert = Swal.fire({text:"Getting course..."})
//             Swal.showLoading()
//             try {
//                 const response = await axios.get(`https://elexdondigitalacademy.com/api/get_lesson_by_course_id.php?id=${courseId}`);
//                 console.log(response.data)
//                 if (response.data.success) {
//                     setLessons(response.data.lessons);
//                 } else {
//                     setError(response.data.error);
//                 }
//             } catch (err) {
//                 setError('An error occurred while fetching course details.');
//                 console.error(err)
//             }finally{
//                 loadingAlert.close();
//             }
//         };
//         fetchCourse();
//     }, [courseId]);
    
    
    
    
//     const handleDeleteLesson = async (lessonId) => {
//         console.log("Lesson ID for deleting:", lessonId); // Confirm ID is passed
    
//         const confirmation = await Swal.fire({
//             title: 'Are you sure?',
//             text: 'You won’t be able to recover this lesson!',
//             icon: 'warning',
//             showCancelButton: true,
//             confirmButtonColor: '#d33',
//             cancelButtonColor: '#3085d6',
//             confirmButtonText: 'Yes, delete it!',
//         });
    
//         if (confirmation.isConfirmed) {
//             try {
//                 const response = await axios.post('https://elexdondigitalacademy.com/api/delete_lesson.php', {
//                     lessonId: lessonId, // Ensure this matches what the backend expects
//                 }, {
//                     headers: {
//                         'Content-Type': 'application/json', // Specify content type if necessary
//                     },
//                 });
    
//                 if (response.data.success) {
//                     Swal.fire('Deleted!', response.data.message, 'success');
//                     setLessons((lessons) => lessons.filter((lesson) => lesson.id !== lessonId)); // Remove deleted lesson from the list
//                 } else {
//                     Swal.fire('Error', response.data.error, 'error');
//                 }
//             } catch (error) {
//                 console.error(error);
//                 Swal.fire('Error', 'Failed to delete the lesson.', 'error');
//             }
//         }
//     };
    
    

//     if (error) {
//         return <ErrorMessage>{error}</ErrorMessage>;
//     }

//     if (!course) {
//         return <LoadingMessage>Loading...</LoadingMessage>;
//     }

//     return (
//         <LessonsContainer>
//             <Wrapper>
//             <CourseTitle>{course.title} LESSONS</CourseTitle>
//             <Back onClick={()=>handleManageLessons(course.id)}>Back</Back>
//             {/* <CourseDescription>{course?.description}</CourseDescription> */}
//             <LessonsGrid>
//                 {lessons?.length > 0 ? (
//                     lessons.sort((a,b)=>a.position - b.position).map(lesson => (
//                         <LessonCard key={lesson.id}>
                            
//                             <LessonTitle>Lesson {lesson.position} - {lesson.title}</LessonTitle>
//                            <ButtonsContainer>
//                            <PlayButton onClick={() => Swal.fire({
//                                title: lesson.title,
//                                 html: `<video width="100%" controls>
//                                         <source src="https://elexdondigitalacademy.com/api/uploads/videos/${lesson.video_file}" type="video/mp4">
//                                         Your browser does not support the video tag.
//                                     </video>`,
//                                 showCloseButton: true,
//                                 showConfirmButton: false,
//                             })}>
//                                 Play Video
//                             </PlayButton>
//                             <Button>Edit</Button>
//                             <Button2 onClick={() => {
//     console.log("Deleting lesson with ID:", lesson.id); // Log the lesson ID
//     handleDeleteLesson(lesson.id);
// }}>Delete</Button2>
//                             {/* <Button2 onClick={()=>handleDeleteLesson(lesson.id)}>Delete</Button2> */}
//                            </ButtonsContainer>
//                         </LessonCard>
//                     ))
//                 ) : (
//                     <ErrorMessage>No lessons available for this course.</ErrorMessage>
//                 )}
//             </LessonsGrid>
//         </Wrapper>
//         </LessonsContainer>
//     );
// };

// export default ManageLessons;








import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import styled from 'styled-components';

// Styled Components
const LessonsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: rgba(0,0,0,0.4);
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    overflow-y: scroll;
    padding: 5px;   
    padding-top: 50px;
    position: relative;
`;

const CourseTitle = styled.h2`
    text-align: center;
    font-size: 1rem;
    color: rgba(0,0,255,0.6);
`;

const LessonsGrid = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
`;

const LessonCard = styled.div`
    width: 100%;
    background-color: lightgray;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: center;
    margin-bottom: 10px;
`;

const LessonTitle = styled.h2`
    font-size: 0.9rem;
    margin-bottom: 5px;
    color: #444;
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

const Button = styled.button`
    padding: 5px;
    margin-top: 5px;
    cursor: pointer;
    background-color: rgba(0,0,255,0.5);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 0.7rem;
`;

const Button2 = styled.button`
    padding: 5px;
    margin-top: 5px;
    cursor: pointer;
    margin-right: 10px;
    background-color: lightgray;
    color: blue;
    border: 1px solid blue;
    border-radius: 5px;
    font-size: 0.7rem;
`;

const Modal = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    position:fixed;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.5);
`;

const ModalTitle = styled.h3`
    margin: 0 0 10px 0;
`;

const ModalInput = styled.input`
    margin-bottom: 10px;
`;

const BackButton = styled.div`
    cursor: pointer;
    border: 1px solid blue;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    color: blue;
    z-index: 99;
`;

const ManageLessons = ({ courseId, course, handleManageLessons }) => {
    const [lessons, setLessons] = useState(null);
    const [error, setError] = useState(null);
    const [editLesson, setEditLesson] = useState(null);
    const [newVideo, setNewVideo] = useState(null);

    useEffect(() => {
        const fetchCourse = async () => {
            const loadingAlert = Swal.fire({ text: "Getting course..." });
            Swal.showLoading();
            try {
                const response = await axios.get(`https://elexdondigitalacademy.com/api/get_lesson_by_course_id.php?id=${courseId}`);
                if (response.data.success) {
                    setLessons(response.data.lessons);
                } else {
                    setError(response.data.error);
                }
            } catch (err) {
                setError('An error occurred while fetching course details.');
            } finally {
                loadingAlert.close();
            }
        };
        fetchCourse();
    }, [courseId]);

    const handleDeleteLesson = async (lessonId) => {
        const confirmation = await Swal.fire({
            title: 'Are you sure?',
            text: 'You won’t be able to recover this lesson!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
        });

        if (confirmation.isConfirmed) {
            try {
                const response = await axios.post('https://elexdondigitalacademy.com/api/delete_lesson.php', {
                    lessonId: lessonId,
                });
                if (response.data.success) {
                    Swal.fire('Deleted!', response.data.message, 'success');
                    setLessons((lessons) => lessons.filter((lesson) => lesson.id !== lessonId));
                } else {
                    Swal.fire('Error', response.data.error, 'error');
                }
            } catch (error) {
                Swal.fire('Error', 'Failed to delete the lesson.', 'error');
            }
        }
    };

    const handleEditLesson = (lesson) => {
        setEditLesson(lesson);
        setNewVideo(null);
    };

    const handleSubmitEdit = async () => {
        const formData = new FormData();
        formData.append('lessonId', editLesson.id);
        formData.append('title', editLesson.title);
        formData.append('position', editLesson.position);
        if (newVideo) {
            formData.append('video', newVideo);
        }

        try {
            const response = await axios.post('https://elexdondigitalacademy.com/api/edit_lesson.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                Swal.fire('Updated!', response.data.message, 'success');
                setLessons((prevLessons) =>
                    prevLessons.map((lesson) => (lesson.id === editLesson.id ? { ...lesson, title: editLesson.title, position: editLesson.position, video_file: response.data.video_file } : lesson))
                );
                setEditLesson(null);
                setNewVideo(null);
            } else {
                Swal.fire('Error', response.data.error, 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to update the lesson.', 'error');
        }
    };

    const handlePlayVideo = (videoFile) => {
        Swal.fire({
            title: 'Playing Video',
            html: `<video width="100%" controls>
                    <source src="https://elexdondigitalacademy.com/api/uploads/videos/${videoFile}" type="video/mp4">
                    Your browser does not support the video tag.
                </video>`,
            showCloseButton: true,
            showConfirmButton: false,
        });
    };

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <LessonsContainer>
            <Wrapper>
                <CourseTitle>{course.title} LESSONS</CourseTitle>
                <BackButton onClick={() => handleManageLessons(courseId)}>Back</BackButton>
                <LessonsGrid>
                    {lessons?.length > 0 ? (
                        lessons.sort((a,b)=>a.position-b.position).map((lesson) => (
                            <LessonCard key={lesson.id}>
                                <LessonTitle>Lesson {lesson.position} - {lesson.title}</LessonTitle>
                                <ButtonsContainer>
                                    <Button onClick={() => handlePlayVideo(lesson.video_file)}>Play Video</Button>
                                    <Button onClick={() => handleEditLesson(lesson)}>Edit</Button>
                                    <Button2 onClick={() => handleDeleteLesson(lesson.id)}>Delete</Button2>
                                </ButtonsContainer>
                            </LessonCard>
                        ))
                    ) : (
                        <div>No lessons available for this course.</div>
                    )}
                </LessonsGrid>
                {editLesson && (
                    <Modal>
                        <ModalTitle>Edit Lesson</ModalTitle>
                        <ModalInput
                            type="text"
                            value={editLesson.title}
                            onChange={(e) => setEditLesson({ ...editLesson, title: e.target.value })}
                            placeholder="Lesson Title"
                        />
                        <ModalInput
                            type="number"
                            value={editLesson.position}
                            onChange={(e) => setEditLesson({ ...editLesson, position: e.target.value })}
                            placeholder="Lesson Position"
                        />
                        <ModalInput
                            type="file"
                            onChange={(e) => setNewVideo(e.target.files[0])}
                        />
                        <Button onClick={handleSubmitEdit}>Save Changes</Button>
                        <Button onClick={() => setEditLesson(null)}>Cancel</Button>
                    </Modal>
                )}
            </Wrapper>
        </LessonsContainer>
    );
};

export default ManageLessons;
