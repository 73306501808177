// SellYourCourse.js
import React from 'react';
import styled from 'styled-components';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const SellYourCourseWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 2rem 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 3rem;
  }
`;

const Content = styled.div`
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 2rem;
  }
`;

const Icon = styled.div`
  font-size: 4rem;
  color: #4a90e2;
  margin-bottom: 1rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: rgba(0,0,255,0.6);
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const GetStartedButton = styled.button`
  background-color: #4a90e2;
  color: white;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #357abf;
  }
`;

const SellYourCourse = () => {
    const navigate = useNavigate()
  return (
    <SellYourCourseWrapper>
      <Content>
        <Icon>
          <FaChalkboardTeacher />
        </Icon>
        <Title>Sell Your Courses</Title>
        <Description>
          You have a unique skill set, and people are eager to learn from you! 
          Whether you're an expert in coding, design, business, or wellness, you can transform lives through your knowledge. 
          Post your course today and inspire others to grow. 
          It’s time to turn your passion into impact and income.
        </Description>
      </Content>
      <GetStartedButton onClick={()=>navigate("/authorlogin")}>Proceed</GetStartedButton>
    </SellYourCourseWrapper>
  );
};

export default SellYourCourse;
