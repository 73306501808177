import React, { useState } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import axios from "axios";
import { clearCart } from "../Features/Slice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Styled Components for the Modal
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const ModalWrapper = styled.div`
  background-color: white;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const BtcWallet = styled.p`
  font-size: 1.2rem;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  word-break: break-all;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  margin: 15px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: #ddd;
  }

  &.send {
    background-color: #4caf50;
    color: white;
  }

  &.cancel {
    background-color: #f44336;
    color: white;
  }
`;

const BtcPaymentModal = ({ btcWallet, totalNgnAmount, totalBtcAmount, onClose }) => {
  const [transactionRef, setTransactionRef] = useState("");
  const [confirmTransactionRef,setConfirmTransactionRef]=useState('')
  const deliveryDetails = useSelector((state) => state.deliveryDetails);
  const cart = useSelector((state) => state.cart);
  const userInfo = useSelector((state) => state.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const calculateTotal = () => {
    return cart.reduce((total, item) => {
      const price = parseFloat(item.price) || 0;
      return total + price;
    }, 0);
  };




  const postOrderFunction = async () => {
    const getCurrentDateTime = () => {
      const now = new Date();
      const offset = now.getTimezoneOffset() * 60000;
      const localTime = new Date(now.getTime() - offset);
      return localTime.toISOString().slice(0, 19).replace("T", " ");
    };

    const orderSummary = {
      userId: userInfo.id,
      date: getCurrentDateTime(),
      transactionRef: transactionRef,
      name: deliveryDetails.name,
      phone: deliveryDetails.phoneNumber,
      email: deliveryDetails.email,
      referrerCode: userInfo.referrerCode,
      cartItems: cart.map(item => ({
        id: item.id,
        title: `${item.title} by ${item.author} - Course ID:${item.id}`,
        price: item.price
      })),
      total: calculateTotal(),
      currency: "NGN"
    };

    try {
      const response = await axios.post("https://elexdondigitalacademy.com/api/save_pending_order.php", orderSummary);
      Swal.fire({
        icon: "success",
        title: "Order Placed",
        text: response.data.message,
      });

      dispatch(clearCart());
      navigate("/userdashboard");
    //   setActivePage('courses');
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Order Failed",
        text: error.response?.data?.error || 'An error occurred. Please try again.',
        showConfirmButton: false,
        timer: 2000
      });
    }
  };


  const sendOrderEmailFunction = async () => {
    const loadingAlert = Swal.fire({
      title: "Loading",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false
    });

    Swal.showLoading();

    const getCurrentDateTime = () => {
      const now = new Date();
      const offset = now.getTimezoneOffset() * 60000;
      const localTime = new Date(now.getTime() - offset);
      return localTime.toISOString().slice(0, 19).replace("T", " ");
    };

    const orderSummary = {
      userId: userInfo.id,
      date: getCurrentDateTime(),
      transactionRef: transactionRef,
      name: deliveryDetails.name,
      phone: deliveryDetails.phoneNumber,
      email: deliveryDetails.email,
      referrerCode: userInfo.referrerCode,
      cartItems: cart.map(item => `${item.title} by ${item.author} Course ID:${item.id} - ₦${new Intl.NumberFormat().format(item.price)}`),
      total: `₦${new Intl.NumberFormat().format(calculateTotal())}`
    };

    try {
      const response = await axios.post("https://elexdondigitalacademy.com/api/send_pending_order_email.php", {
        buyerEmail: deliveryDetails.email,
        sellerEmail: 'info@elexdontech.com',
        orderSummary: JSON.stringify(orderSummary, null, 2)
      });

      if (response.status === 200) {
        Swal.fire({ icon: "success", text: "Thank you for your order. Your payment is being confirmed and your order shall appear in your dashboard as soon as your payment is confirmed" });
      } else {
        Swal.fire({ icon: "error", text: "Failed to send order summary." });
      }
    } catch (error) {
      Swal.fire({ icon: "error", text: "An error occurred while sending the order summary." });
    } finally {
      loadingAlert.close();
    }
  };


  const handleOrder = ()=>{
    if (!transactionRef) {
        Swal.fire({ icon: "warning", text: "Please enter the transaction reference." });
        return;
      }else if(transactionRef!==confirmTransactionRef){
        Swal.fire({ icon: "warning", text: "Transaction Reference does not match." });
        return;
      }
    postOrderFunction();
    sendOrderEmailFunction();

}




  return (
    <ModalBackground>
      <ModalWrapper>
        <h2>BTC Payment</h2>
        <p>Please transfer the BTC equivalent of your total to the following wallet, enter the transaction refrence after payment and then click the send button:</p>

        {/* Displaying BTC Wallet Address */}
        <BtcWallet>BTC wallet Address: <br/>{btcWallet}</BtcWallet>

        {/* Displaying Amounts in NGN and BTC */}
        <p>Total Amount in NGN: <strong>₦{totalNgnAmount.toLocaleString()}</strong></p>
        <p>Total Amount in BTC: <strong>{totalBtcAmount} BTC</strong></p>

        {/* Input for transaction reference */}
       
        <Input
          type="text"
          placeholder="Enter your btc payment transaction reference"
          value={transactionRef}
          onChange={(e) => setTransactionRef(e.target.value)}
        />
        <Input
          type="text"
          placeholder="Enter your btc payment transaction reference again"
          value={confirmTransactionRef}
          onChange={(e) => setConfirmTransactionRef(e.target.value)}
        />

        {/* Buttons: Send & Cancel */}
        <ButtonGroup>
          <Button className="send" onClick={handleOrder}>Send</Button>
          <Button className="cancel" onClick={onClose}>Cancel</Button>
        </ButtonGroup>
      </ModalWrapper>
    </ModalBackground>
  );
};

export default BtcPaymentModal;
