// src/Dashboard.js
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';
import { userLogout } from '../Features/Slice';
// import MyCoursesPage from './MyCoursesPage';
import ProfilePage from "../components/ProfilePage"
import MyCoursesPage from "../components/MyCoursesPage"
import '../CSS/Dashboard.css';
import Swal from 'sweetalert2';
import { Context } from './Context';
import AllAffiliates from './AllAffiliates';
import AllUsers from './AllUsers';
import AdminPostCourse from './AdminPostCourse';
import AdminViewAllCourses from './AdminViewAllCourses';
import AllAuthors from './AllAuthors';
import PendingOrders from './PendingOrders';



const Dashboard = () => {
  const {activePage, setActivePage}=useContext(Context)
  const userInfo = useSelector(state=>state.userInfo)
  const location = useLocation();
  
 
  const dispatch = useDispatch();
  const navigate = useNavigate()
  // const history = useHistory();

  const handleLogout = () => {
    Swal.fire({
      icon: 'warning',
      // title: 'Sign Up Successful',
      text: 'Are you sure you want to logout?',
      confirmButtonText: 'Yes',
      showCancelButton:true
    }).then((result) => {
      if (result.isConfirmed) { 
        dispatch(userLogout());
      }
    });
  };
  

  const navigateToCart = () => {
    navigate('/cart'); // Assuming '/cart' is your cart route
  };

  const [adminswitch,setAdminSwitch]=useState(false)
  const [adminMenu,setAdminMenu]=useState(0)


  return (
    <div key={location.pathname}>
      {adminswitch===false?<div className="dashboard">
      <nav className="dashboard-nav">
        <button onClick={() => setActivePage('profile')}>Profile</button>
        <button onClick={() => setActivePage('courses')}>My Courses</button>
        {userInfo.email==="esomesther@gmail.com"||userInfo.email==="support@elexdondigitalacademy.com"?<button onClick={()=>setAdminSwitch(true)}>Switch to Admin</button>:""}
        <button onClick={handleLogout}>Logout</button>
      
      </nav>
      <div className="dashboard-content">
        {activePage === 'profile' && <ProfilePage />}
        {activePage === 'courses' && <MyCoursesPage />}
      </div>
    </div>:
    <div className='AdminDashboard'>
      <div className='AdminDashboardMenu'>
          <p onClick={()=>setAdminMenu(0)} className={adminMenu===0?'AdminDashboardMenuPActive':'AdminDashboardMenuP'}>Users</p>
          <p onClick={()=>setAdminMenu(1)} className={adminMenu===1?'AdminDashboardMenuPActive':'AdminDashboardMenuP'}>Affiliates</p>
          <p onClick={()=>setAdminMenu(2)} className={adminMenu===2?'AdminDashboardMenuPActive':'AdminDashboardMenuP'}>Authors</p>
          <p onClick={()=>setAdminMenu(3)} className={adminMenu===3?'AdminDashboardMenuPActive':'AdminDashboardMenuP'}>Pending Orders</p>
          <p onClick={()=>setAdminSwitch(false)} className='AdminDashboardMenuP'>Switch to User</p>
      </div>
      <div className='AdminDashboardContentWrap'>
          {adminMenu===1&&<AllAffiliates/>}
          {adminMenu===0&&<AllUsers/>}
          {adminMenu===2&&<AllAuthors/>}
          {adminMenu===3&&<PendingOrders/>}
          

      </div>
      
      </div>}
    </div>

  );
};

export default Dashboard;
