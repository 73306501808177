import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart } from '../Features/Slice';
import "../CSS/CartPage.css";
import { FaCartShopping, FaDeleteLeft, FaRecycle } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearCart } from '../Features/Slice';

const CartPage = () => {
  const location = useLocation();
    const navigate = useNavigate()
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);

  const handleRemoveFromCart = (itemId) => {
    dispatch(removeFromCart(itemId));
  };

  // Calculate the total cart amount
  const totalAmount = cart.reduce((acc, item) => {
    // Ensure item.price is a valid number, defaulting to 0 if not
    const price = parseFloat(item.price) || 0;
    return acc + price;
  }, 0);
  

  const handleCheckout = () => {
    // Handle checkout logic here, e.g., redirect to a checkout page or show a confirmation message
    navigate("/deliverydetailpage");
  };

  return (
    <div className="cart-page" key={location.pathname}>
      <h2><FaCartShopping /> YOUR CART </h2>
      <div className="cart-items">
        {cart.map(item => (
          <div className="cart-item" key={item.id}>
            <img src={`https://elexdondigitalacademy.com/api/uploads/${item.image}`} alt={item.title} />
            
            <div className="item-details">
              <h3>{item.title}</h3>
              <p style={{fontSize:"small",color:"gray"}}>Course ID: {item.id}</p>
              <p style={{fontSize:"small",color:"gray"}}>{item.author}</p>
              {/* <p>₦ {item.price.toFixed(2)}</p> */}
             
              <p>₦ {Number(item.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>


              <button onClick={() => handleRemoveFromCart(item.id)}>Remove</button>
            </div>
          </div>
        ))}
      </div>
      <p style={{color:"blue",fontWeight:"bold",cursor:"pointer",textAlign:"center"}} onClick={()=>dispatch(clearCart())}>Clear Cart</p>
      
      {cart.length===0?<div className="cart-summary" style={{textAlign:"center"}}>Your Cart is empty. <span style={{color:"blue",fontWeight:"bold",cursor:"pointer"}} onClick={()=>navigate("/allcourses")}>Continue to Courses</span></div>
      :<div className="cart-summary">
        <h3>Total Items: {cart.length}</h3>
        {/* <h3>Total Amount: ₦ {totalAmount.toFixed(2)}</h3> */}
        <h3>Total Amount: ₦ {Number(totalAmount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>


        <button className="checkout-button" onClick={handleCheckout}>Checkout</button>
      </div>}
    </div>
  );
};

export default CartPage;
