

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import '../CSS/AllAffiliates.css'; // Import the CSS file
import { Context } from './Context';

const AllAffiliates = () => {
  const { getAllAffiliatesUrl } = useContext(Context);
  const [affiliates, setAffiliates] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchAffiliates = async () => {
      try {
        const response = await axios.get(getAllAffiliatesUrl);

        // Check if the response status is 'success'
        if (response.data.status === 'success') {
          setAffiliates(response.data.data); // Accessing the 'data' field
        } else {
          console.error('Failed to fetch affiliates:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching affiliates:', error);
      }
    };

    fetchAffiliates();
  }, [getAllAffiliatesUrl]); // Added dependency for better effect management

  const filteredAffiliates = affiliates.filter(affiliate =>
    affiliate.affiliate_code.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="affiliates-wrapper" style={{ width: "100%" }}>
      <h1 className="affiliates-heading">Affiliates</h1>
      <input
        type="text"
        placeholder="Search by Referral Code"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="affiliates-search-input"
      />
      <div className="affiliates-list">
        {filteredAffiliates.length > 0 ? (
          filteredAffiliates.map((affiliate) => (
            <div key={affiliate.id} className="affiliate-card">
              <div className="affiliate-detail">
                <span className="affiliate-label">Name:</span>
                <span className="affiliate-value">{affiliate.first_name} {affiliate.last_name}</span>
              </div>
              <div className="affiliate-detail">
                <span className="affiliate-label">Email:</span>
                <span className="affiliate-value">{affiliate.email}</span>
              </div>
              <div className="affiliate-detail">
                <span className="affiliate-label">Referral Code:</span>
                <span className="affiliate-value">{affiliate.affiliate_code}</span>
              </div>
              <div className="affiliate-detail">
                <span className="affiliate-label">Payment Detail:</span>
                <span className="affiliate-value">{affiliate.bank_account}</span>
              </div>
            </div>
          ))
        ) : (
          <div>No affiliates found.</div>
        )}
      </div>
    </div>
  );
};

export default AllAffiliates;

