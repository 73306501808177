import React, { useContext, useEffect, useState } from 'react';
import { FaCartShopping } from 'react-icons/fa6';
import { FaShareAlt } from 'react-icons/fa';
import "../CSS/LatestCourses.css";
import Swal from 'sweetalert2';
import { addToCart } from '../Features/Slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Context } from './Context';
import axios from 'axios';

const LatestCourses = () => {
const [courses,setCourses]=useState([])
const [courses2,setCourses2]=useState([])
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Fetch courses by author "ELEXDON DIGITAL ACADEMY"
  useEffect(() => {
    const fetchCourses = async () => {

      try {
        const response = await axios.get(
          'https://elexdondigitalacademy.com/api/get_courses_by_elexdon_digital_academy.php'
        );

        if (response.data.success) {
          setCourses(response.data.courses);
          // console.log(response.data.courses)
        } else {
          Swal.fire({text:response.data.error});
        }
      } catch (err) {
        console.error('Error fetching courses:', err);
        Swal.fire({text:'Failed to fetch courses. Please try again.'});
      } finally {
        // setLoading(false);
      }
    };

    fetchCourses();
  }, []);


  useEffect(() => {
    const fetchCourses = async () => {
      try {
        // Make the API request to fetch courses excluding "ELEXDON DIGITAL ACADEMY"
        const response = await axios.get(
          'https://elexdondigitalacademy.com/api/get_courses_by_all_other_authors.php'
        );

        // Handle the response and update state
        if (response.data.success) {
          setCourses2(response.data.courses);
          // console.log(response.data.courses); // Debugging: log fetched courses
        } else {
          Swal.fire({ text: response.data.error });
        }
      } catch (err) {
        console.error('Error fetching courses:', err);
        Swal.fire({ text: 'Failed to fetch courses. Please try again.' });
      } 
    };

    fetchCourses(); // Call the function to fetch the data
  }, []); // Runs once when the component mounts

  const handleAddToCart = (course) => {
    dispatch(addToCart(course));
    Swal.fire({ icon: "success", text: "This course is added to your cart", showConfirmButton: false, timer: 2000 });
  };

  const handleShare = (course) => {
    const courseUrl = `${window.location.origin}/course/${course.id}`;
    const courseDetails = `Check out this course: ${course.title} - ₦${Number(course.price).toLocaleString()}\n${courseUrl}`;
    if (navigator.share) {
      navigator.share({
        title: course.title,
        text: courseDetails,
        url: courseUrl,
      }).catch(error => console.error('Error sharing', error));
    } else {
      navigator.clipboard.writeText(courseDetails);
      alert("Course details copied to clipboard");
    }
  };


  

  return (
   <div>
      <div className='LatestCourses'>
      <div className='LatestCoursesHeader'>
        <h1>LATEST COURSES BY ELEXDON DIGITAL</h1>
        <button onClick={() => navigate("/allcourses")}>View All Courses</button>
      </div>
      <div className='LatestCoursesBody'>
        {courses.slice(0, 8).map(course => (
          <div className='Card' key={course.id}>
            <div className='CardUp'>
              <img src={`https://elexdondigitalacademy.com/api/uploads/${course.image}`} 
              alt={course.title} 
              onClick={() => navigate(`/course/${course.id}`)}
              style={{cursor:"pointer"}}
              />
            </div>
            <div className='CardDown'>
              <div className='CardDownAuthor'>
                <span className='CardDownAuthorLeft'>{course.author.slice(0,1)}</span>
                <span className='CardDownAuthorRight' style={{fontSize:"small"}}>{course.author}</span>
              </div>
              <h4>{course.title.slice(0,40)}{course.title.length > 40 ? '...' : ""}</h4>
              {/* <span style={{ fontSize: "12px", color: "gray" }}>{course.id}</span> */}
              <p>₦ {Number(course.price).toLocaleString()}</p>
              <p onClick={() => navigate(`/course/${course.id}`)} style={{ marginBottom: "5px", fontWeight: "400", fontSize: "0.8rem", cursor: "pointer" }}>View More</p>
              <div className='CardButtonAndShareWrap'>
                <button onClick={() => handleAddToCart(course)}><FaCartShopping /> Buy Now</button>
                <FaShareAlt className='CardShare' onClick={() => handleShare(course)} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    <div className='LatestCourses'>
      <div className='LatestCoursesHeader'>
        <h1>LATEST COURSES BY OUR SELLERS</h1>
        <button onClick={() => navigate("/allcourses")}>View All Courses</button>
      </div>
      <div className='LatestCoursesBody'>
        {courses2.slice(0, 8).map(course => (
          <div className='Card' key={course.id}>
            <div className='CardUp'>
            <img src={`https://elexdondigitalacademy.com/api/uploads/${course.image}`} 
              alt={course.title} 
              onClick={() => navigate(`/course/${course.id}`)}
              style={{cursor:"pointer"}}
              />
            </div>
            <div className='CardDown'>
              <div className='CardDownAuthor'>
                <span className='CardDownAuthorLeft'>{course.author.slice(0,1)}</span>
                <span className='CardDownAuthorRight' style={{fontSize:"small"}}>{course.author}</span>
              </div>
              <h4>{course.title.slice(0,40)}{course.title.length > 40 ? '...' : ""}</h4>
              {/* <span style={{ fontSize: "12px", color: "gray" }}>{course.id}</span> */}
              <p>₦ {Number(course.price).toLocaleString()}</p>
              <p onClick={() => navigate(`/course/${course.id}`)} style={{ marginBottom: "5px", fontWeight: "400", fontSize: "0.8rem", cursor: "pointer" }}>View More</p>
              <div className='CardButtonAndShareWrap'>
                <button onClick={() => handleAddToCart(course)}><FaCartShopping /> Buy Now</button>
                <FaShareAlt className='CardShare' onClick={() => handleShare(course)} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
   </div>
  );
}

export default LatestCourses;
